<template>
  <div>
    <TopBar :showBack="true"/>
    <AppTitle title="Reset Password"/>
    <div class="pt-6">
      <p>Create your new password.</p>
      <div class="mt-6" v-if="error_message.length > 0">
        <h3 class="font-medium text-red-500">
          {{ error_message }}
        </h3>
      </div>
      <div class="pt-8">
        <form class="" @submit.stop.prevent="resetPassword">
          <div class="mb-4">
            <input type="email" v-model="form.email" class="px-6 py-5 rounded-xl border border-gray-100 w-full focus:ring-red-500 focus:border-red-500" placeholder="Email">
          </div>
          <div class="mb-4">
            <input type="password" v-model="form.password" class="px-6 py-5 rounded-xl border border-gray-100 w-full focus:ring-red-500 focus:border-red-500" placeholder="New Password">
          </div>
          <div class="mb-4">
            <input type="password" v-model="form.password_confirmation" class="px-6 py-5 rounded-xl border border-gray-100 w-full focus:ring-red-500 focus:border-red-500" placeholder="Repeat New Password">
          </div>
          <div class="mt-4">
            <button v-show="!loading" class="bg-red-500 text-white font-gothic w-full py-4 rounded-xl border border-transparent text-lg">Submit</button>
            <button class="bg-red-400 text-white font-gothic w-full py-4 rounded-xl border border-transparent" v-show="loading" disabled>
              <font-awesome-icon icon="fas fa-circle-notch" class="text-white animate-spin"/>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  import TopBar from '@/components/TopBar.vue';
  import AppTitle from '@/components/AppTitle.vue';
  export default {
    components: {
      TopBar,
      AppTitle
    },
    data () {
      return {
        error_message: '',
        loading: false,
        success: false,
        form: {
          email: '',
          password: '',
          password_confirmation: '',
          token: ''
        }
      }
    },
    methods: {
      async resetPassword () {
        this.loading = true;
        this.form.token = this.$route.query.token;
        await this.$store.dispatch('auth/resetPassword', this.form)
        .then( () => {
          this.$router.push({name:'welcome', hash:'#reset'});
        })
        .catch( error => {
          if (error.response && error.response.status === 422 ){
            this.error_message = "There was an error. Please try again.";
          }
        })
        .finally( () => {
          this.loading = false;
        })
      },
    }
  }
</script>
